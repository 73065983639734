import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';

import { ConfirmationModalOverlayRef } from '../classes/confirmation-modal.ref';
import { ConfirmationModalData } from '../interfaces/confirmation-modal-data';
import { CONFIRMATION_MODAL_DATA } from '../tokens/confirmation-data.token';

import { ConfirmationComponent } from '../components/confirmation/confirmation.component'
import { customOverlayPosition } from '../interfaces/customPosition-modal-data';
import { CustomOverlayAnimationConfig } from '../interfaces/animation-modal-data';
import { CommonFunctionsService } from './common-functions.service';


export interface ConfirmationModalConfig {
    panelClass?: string;
    hasBackdrop?: boolean;
    backdropClass?: string;
    data?: ConfirmationModalData;
    isCentered?: boolean;

    customOverlayPosition?: customOverlayPosition | null;

    isAnimation?: CustomOverlayAnimationConfig | null;
}

const DEFAULT_CONFIG: ConfirmationModalConfig = {
    hasBackdrop: true,
    backdropClass: 'my-overlay-modal-backdrop',
    panelClass: 'my-confirmation-panel'
};


@Injectable()
export class ConfirmationModalService {
    public dialogRef!: ConfirmationModalOverlayRef;
    constructor(
        private injector: Injector,
        private overlay: Overlay,
        private _CommonFunctionsService: CommonFunctionsService
    ) { }

    open<T>(component: ComponentType<T>, config: ConfirmationModalConfig = {}) {
        // Returns an OverlayRef (which is a PortalHost)
        const modalConfig = { ...DEFAULT_CONFIG, ...config };
        const overlayRef = this.createOverlay(modalConfig);
        const dialogRef = new ConfirmationModalOverlayRef(overlayRef);

        const overlayComponent = this.attachModalContainer(overlayRef, modalConfig, dialogRef,component);

        this.dialogRef = dialogRef;
        return dialogRef;
    }

    private attachModalContainer<T>(overlayRef: OverlayRef, config: ConfirmationModalConfig, dialogRef: ConfirmationModalOverlayRef,component: ComponentType<T>) {
        const injector = this.createInjector(config, dialogRef);

        const containerPortal = new ComponentPortal(component, null, injector);
        const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);
        return containerRef.instance;
    }

    private createInjector(config: ConfirmationModalConfig, dialogRef: ConfirmationModalOverlayRef): PortalInjector {
        const injectionTokens = new WeakMap();

        injectionTokens.set(ConfirmationModalOverlayRef, dialogRef);
        injectionTokens.set(CONFIRMATION_MODAL_DATA, config.data);
        return new PortalInjector(this.injector, injectionTokens);
    }

    private getOverlayConfig(config: ConfirmationModalConfig): OverlayConfig {
        let positionStrategy = this.overlay.position()
            .global();

        positionStrategy = this._CommonFunctionsService.setPositionStrategy(config, positionStrategy);
        let panelCls: any = this._CommonFunctionsService.setMdlAnimation(config, config.panelClass);

        const overlayConfig = new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: panelCls,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }

    private createOverlay(config: ConfirmationModalConfig) {
        // Returns an OverlayConfig
        const overlayConfig = this.getOverlayConfig(config);

        // Returns an OverlayRef
        return this.overlay.create(overlayConfig);
    }
}

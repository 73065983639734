<div class="customTblFilterHeaderBox">
    <div *ngIf="tableOptions.config.pagger.length > 0">
        <span>Pages</span>
        <div class="form-group">
            <select class="form-control" (change)="_PytCustTblService.dtUpdateByPageLength($event,tableOptions)">
            <option *ngFor="let item of tableOptions.config.pagger"
            [selected]="tableOptions.config.totalItmePerPage == item" [value]="item">
                {{item}}</option>
        </select>
        </div>
        &nbsp;
        <span>entries</span>
    </div>

    <!-- <div *ngIf="tableOptions.config.searchType">
        <input type="text" appSrchDbounce appSearchBox [debounceTime]="500" (onEvent)="_PytCustTblService.dtSearchBy($event,tableOptions)" type="text" placeholder="Search" class="dtSearch" placeholder="Search...">
    </div> -->

    <div>
        <span>Showing {{tableOptions.config.fromRec}} to {{tableOptions.config.toRec}} of
        {{tableOptions.config.totalCount}} entries</span>

        <div>
            <span [ngClass]="{'pagerMute':tableOptions.config.currentPage == 1}" (click)="tableOptions.config.currentPage > 1? _PytCustTblService.dtUpdateByPagination('back',tableOptions) : false"><i class="fas fa-chevron-left"></i></span>
            <span [ngClass]="{'pagerMute':tableOptions.config.totalPages == tableOptions.config.currentPage}" (click)="tableOptions.config.totalPages > tableOptions.config.currentPage? _PytCustTblService.dtUpdateByPagination('next',tableOptions): false"><i class="fas fa-chevron-right"></i></span>
        </div>

        <a href="javascript:void(0)" *ngIf="tableOptions.config.reloadTable" class="syncBtn" [class]="{'rotateIt': tableOptions.config.rotateIt}" (click)="tableOptions.dtUpdateFnCallBack()" rel="noopener noreferrer"><i class="fa fa-refresh"></i></a>

        <div *ngIf=" tableOptions.records.length > 0  && tableOptions.config.downloadReports">
            <div >  
            <button  class="btn btn-danger ml-2 "><i class="fas fa-file-download mr-1 "></i>Export</button>
        </div>

    </div>

</div>
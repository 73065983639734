import { NgModule } from '@angular/core';

import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { FormModalComponent } from './components/form-modal/form-modal.component';
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import { ComponentModalService } from './services/component-modal.service';
import { ConfirmationModalService } from './services/confirmation-modal.service';
import { TemplateModalService } from './services/template-modal.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CommonFunctionsService } from './services/common-functions.service';



@NgModule({
    declarations: [
        ConfirmationComponent,
        FormModalComponent,
        CustomModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        OverlayModule,
        PlatformModule,
        ObserversModule,
        PortalModule
    ],
    providers: [
        ComponentModalService,
        ConfirmationModalService,
        TemplateModalService,
        CommonFunctionsService
    ],
    exports: [
        OverlayModule,
        PlatformModule,
        ObserversModule,
        PortalModule,

        ConfirmationComponent,
        FormModalComponent,
        CustomModalComponent
    ],
    entryComponents: [
        FormModalComponent,
        ConfirmationComponent
    ]
})
export class CdkCustomMdlModule { }

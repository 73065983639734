import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../shared/helper/session-storage.service';
import { ApiService } from '../../shared/services/api.service ';
import { config } from '../../shared/services/config'

@Injectable({
  providedIn: 'root'
})
export class AdminSettingService {
  constructor(
    private apiRequest:ApiService,private sessionStorage: SessionStorageService,private route:Router
  ) { }
  statelist(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.state.statelist);
  }
  addstate(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.state.addstate);
  }
  getstateby(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.state.getstatebyid);
  }
  updatestate(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.state.updatestatus);
  }
  deletestate(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.state.deletestate);
  }
  operatorlist(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.operator.operatorlist);
  }
  addoperator(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.operator.addoperator);
  }
  getoperatorbyid(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.operator.getoperatorbyid);
  }
  updateoperator(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.operator.updateoperator);
  }
  deleteoperator(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.operator.deleteoperator);
  }
  updateoperatorstatus(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.operator.updatestatus);
  }
  addapis(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.apis.addapis);
  }
  apislist(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.apis.apislist);
  }
  apisdelete(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.apis.apisdelete);
  }
  getapisbyid(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.apis.getapisbyid);
  }
  updateapisstatus(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.apis.updatestatus);
  }
  updateapis(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.apis.updateapis);
  } 

  addstateapis(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.apis.addstateapis);
  }
  
  switchapilist(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.apis.apislist);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showNullResult'
})
export class ShowNullResultPipe implements PipeTransform {

  transform(value: any, param?: any): any {
    
    if (!value) {
      
      return param === 'Boolean' ? 0 : param ? param : '-';
    }
    // if ((value === '' || value === null || value === undefined) && (param || param === undefined)) {
    //   return param === undefined ? '-' : param;
    // }
    //else if ((value === '' || value === null || value === undefined) && param === 'Boolean') {
    //   return '0';
    // }

    return value;
  }

}

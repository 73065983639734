 
import { OtpModalComponent } from './otp-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDynamicOtpModule } from '../../components/custom-dynamic-otp/custom-dynamic-otp.module'; 



@NgModule({
  declarations: [OtpModalComponent],
  imports: [
    CommonModule,
    CustomDynamicOtpModule
  ],
  exports: [OtpModalComponent]
})
export class OtpModalModule { }

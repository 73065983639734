
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs'; 
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';  
import { catchError, map } from 'rxjs/operators';
import { SessionStorageService } from '../helper/session-storage.service';
import { LoadingService } from '../services/loading.service';
@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  loginSession: any;

  constructor(private route: Router, private loader: LoadingService,   private store:SessionStorageService,) {}

 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
     
     this.loader.isLoading.next(true);

      let session  = this.store.getItem('user') ? JSON.parse(this.store.getItem('user')):null;
      // console.log(request.url);
     this.loginSession = session?.authtoken;
    // console.log(this.loginSession);
    if (request.url.includes("auth/login") || request.url.includes('auth/forgot-password') ||request.url.includes('auth/verify') ||request.url.includes('auth/validate-otp') ||request.url.includes('upload/upload-image') ) { //header is not needed in login api
      request = request.clone({
        headers: request.headers.set('token', '22509F2AE7BA71E4C3FB32AB94B6CEA8'),
      })
    }
    else if (this.loginSession != undefined) {
      console.log("Setting Token")

      //this.loader.isLoading.next(true);

      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.loginSession)
        .set('token', '22509F2AE7BA71E4C3FB32AB94B6CEA8')
      })
    } 
    return next.handle(request).pipe(

      catchError(err => {
        console.log("err.msg", err);
        this.loader.hide();
        if (err.status === 401 || err.status === 2001) {
          // this.authCoreService.logout();
          // this.authCoreService.clearlocalsession();
          this.store.clearStorage();
          this.route.navigate(['/auth/login']);
          // window.location.reload();
          // this.authCoreService.isSessionTimeOut.next(true);
         
        } else if (err.status === 500) {
          console.log("err.msg", err.msg);
          
        
          // this.toaster.showError(err.msg, 'error')
        }
        const error = err.error.message || err.statusText;
        //console.log("error", error);
        return throwError(() => error)
      }),
      map(event => {
        if (event instanceof HttpResponse) {
        }
       this.loader.hide();
        // console.log(event)
        return event;
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../helper/session-storage.service';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
    providedIn: 'root'
})

export class SecureInnerPagesGuard implements CanActivate {
    signUpData: any =[];
    constructor(private authService: AuthService,
        private router: Router,private store:SessionStorageService,) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        
        let user  = this.store.getItem('user') ? JSON.parse(this.store.getItem('user')):null
        this.signUpData = user?.authtoken; 
         console.log(this.signUpData);
        if (this.signUpData) { 
            return true
        }
        sessionStorage.clear();
        this.router.navigate(['/auth/login']);  
        return false;
    }
}
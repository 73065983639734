import { LoaderService } from './../../services/loader.service';
import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { AuthService } from 'src/app/auth/auth.service';
import { SessionStorageService } from '../../helper/session-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  storagedata:any;
  public elem: any;
  public dark: boolean = this.layout.config.settings.layout_version == 'dark-only' ? true : false;
  userbalance:any;
  constructor(public layout: LayoutService,
    public navServices: NavService, 
    private api:AuthService,
    private store:SessionStorageService,
    private loader:LoaderService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.getwalletbal();
  }

  ngOnInit() {
    this.elem = document.documentElement;
    let storageData = JSON.parse(this.store.getItem('user'));
    this.storagedata = storageData.data;
    this.loader.loaderEvent.subscribe((data: any) => {
      if (!data) {
        this.getwalletbal();
      }
    });
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu  = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    this.dark = !this.dark;
    this.layout.config.settings.layout_version = this.dark ? 'dark-only' : 'light';
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
 
  getwalletbal(){
    //auth/userBalance 
    const formdata = new FormData(); 
    this.api.getbalnce(formdata).subscribe((res: any) => {
      if (res.statuscode == 200) { 
        this.userbalance = res.balance;
    }
    })
   
  }

}

<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">     
        <div class="login-card">
          <div>
            <div>
              <a class="logo" routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
              </a>
            </div>
            <div class="login-main"> 
              <form class="theme-form" [class.was-validated]="!forgotform.valid"  [formGroup]="forgotform">
                <h4>Reset Your Password</h4>
                <div class="form-group">
                  <label class="col-form-label">Enter Your Username</label>
                  <div class="row"> 
                    <div class="col-md-12">
                      <input class="form-control mb-1" type="text" formControlName="username" placeholder="enter username">
                      <div class="invalid-tooltip" *ngIf="forgotform.controls.username.touched && forgotform.controls.username.errors?.required">Username is required.</div>
                    </div>
                    <div class="col-12">
                      <button (click)="sendotp()"   [disabled]="!forgotform.valid" class="btn btn-primary btn-block m-t-10" type="submit">Send</button>
                    </div>
                  </div>
                </div>
              
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
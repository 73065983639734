import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  // public show: boolean = true;
  active: boolean = true;
  // constructor() {
  //   setTimeout(() => {
  //     this.show = false;
  //   }, 3000);
  // }
  constructor(private loader: LoadingService) {
    this.loader.isLoading.subscribe((isLoading: boolean) => {
      console.log('get behavour',isLoading);
      setTimeout(() => {
        this.active = isLoading;
      }, 100);
    });
  }
  ngOnInit() { }

  ngOnDestroy() { }

} 

  

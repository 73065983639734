import { Component, Inject, OnInit } from '@angular/core';
import { FormModalOverlayRef } from '../../classes/form-modal.ref';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { COMPONENT_MODAL_DATA } from '../../tokens/component-data.token';

@Component({
    selector: 'app-form-modal',
    templateUrl: './form-modal.component.html',
    styleUrls: ['./form-modal.component.css']
})
export class FormModalComponent implements OnInit {
    public form: FormGroup;

    constructor(
        private fb: FormBuilder,
        public dialogRef: FormModalOverlayRef,
        @Inject(COMPONENT_MODAL_DATA) public data: any
    ) {
        this.form = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required]
        });

        console.log(this.data);
        // console.log(this);

    }

    ngOnInit() {
    }

    onSubmit() {
        if (this.form.valid) {
            console.log('form is valid, save it...');
            this.dialogRef.close(this.data);
        }
    }

    cancel() {
        this.form.reset();
        this.dialogRef.close(this.data);
    }

}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'; 
import { pytCustTblOptions, PytCustTblService } from '../pyt-cust-tbl.service';
import Swal from 'sweetalert2';
// import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-pyt-tbl-top-box',
  templateUrl: './pyt-tbl-top-box.component.html',
  styleUrls: ['./pyt-tbl-top-box.component.css'],
})
export class PytTblTopBoxComponent implements OnInit {
  @Input() tableOptions!: pytCustTblOptions;

  @Input() rotateIt: boolean = false;
  @Output() reloadTbl = new EventEmitter<void>();

  @Input() isReportCanDownload: boolean = false;
  @Output() downloadReport = new EventEmitter<void>();

 
  constructor(
    public _PytCustTblService: PytCustTblService,  
  ) { }

  ngOnInit(): void { }

  // download() {
  //   let formdata = this.tableOptions.config.downloadReports?.formData();
  //   this.auth
  //     .postdata(formdata, this.tableOptions.config.downloadReports?.url)
  //     .subscribe((res: any) => {
  //       if (res.statuscode == 200) {
  //         const fileName = this.tableOptions.config.downloadReports?.fileName + '.xlsx';
  //         const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res['data']);
  //         const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //         XLSX.utils.book_append_sheet(wb, ws, this.tableOptions.config.downloadReports?.fileName);
  //         XLSX.writeFile(wb, fileName);
  //       } else {
  //         Swal.fire({
  //           icon: 'error',
  //           title: res.message,
  //         });
  //       }
  //     });
  // }
}

import { OverlayRef } from '@angular/cdk/overlay';
import { CustomOverlayConfig } from '../services/template-modal.service';

export class TemplateModalOverlayRef {

    constructor(private overlayRef: OverlayRef) { }

    close(customConfig?: CustomOverlayConfig): void {
        if (customConfig?.isAnimation?.outAnimationStyle) {
            this.overlayRef.addPanelClass('animate__'+customConfig.isAnimation.outAnimationStyle)
            setTimeout(() => {
                this.overlayRef.dispose();
            }, 600);
        } else {
            this.overlayRef.dispose();
        }
    }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/shared/helper/session-storage.service';
import { ApiService } from 'src/app/shared/services/api.service ';
import { config } from 'src/app/shared/services/config';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  constructor(private apiRequest:ApiService,private sessionStorage: SessionStorageService,private route:Router) { }

  credlist(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.cred.credlist);
  };
  getUser_data(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.cred.getUser_data);
  };
  editCredentials_data(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.cred.editCredentials_data);
  };

  addCredentials(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.cred.addCredentials);
  };
  addAuthorisedkey(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.cred.addAuthorisedkey);
  };
   
}

import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from 'src/app/shared/helper/session-storage.service';
import { ApiService } from 'src/app/shared/services/api.service ';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  storagedata:any = [];
  constructor(
     
    private authService: AuthService,
    private store:SessionStorageService,
  ) { }

  ngOnInit() {
  let storageData = JSON.parse(this.store.getItem('user'));
  this.storagedata = storageData.data;
  console.log(this.storagedata); 
  }

  logout(){
    this.authService.logout();
  }

}



<div class="form-row px-3">
  <div class="form-group mx-auto mt-3">
    <h6>Enter your OTP </h6>
    <div class="otp-wrap">
      <input #otpInput [type]="onlyNumbers" [(ngModel)]="otp" class="form-control" [attr.maxlength]="otpMaxLength"
        [attr.minlength]="otpMinLength" (keyup)="keyUpEvent($event)" placeholder="OTP">  

       
        <div class="mt-3">
          <span *ngIf="submitOtp">
            <button type="submit ml-2" class="btn btn-primary-outline" (click)="onSubmitOTP()">Submit OTP</button>
          </span>
        </div>

        <div class="ml-2 d-flex mt-3 otp-recd">
          <span class="mr-2">didn't get the OTP?</span>
          <a href="javascript:void(0)" (click)="onResendOtp()">Resend OTP</a>
        </div>
   
    </div>
  </div>
</div>

<div id="trans-recept-modal" class="modal justify-content-center align-items-center d-flex data-tb d-block">

  <div class="modal-content">
    <div class="modal-header">
      <span class="close-modal close" (click)="closeComponentModal()">×</span>
    </div>
    <div class="modal-body ">
      <div class="otp">
        <h3>Please enter the 6-digit verification code we sent via SMS:</h3>
        <div class="forgot-img"><img img="" src="assets/otp.png"></div>
        <div class="">
          <custom-dynamic-otp  [otpMaxLength]="otpMaxLength" [otpMinLength]="otpMinLength"  [onlyNumbers]="onlyNumbers"  (submit)="onSubmit($event)"  (resendOtp)="onResendOtp(true)"    [submitOtp]="submitOtp" ></custom-dynamic-otp>
        </div>
      
      </div>

    </div>
  </div>
</div>

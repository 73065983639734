<div class="media profile-media">
  <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
  <div class="media-body"><span>{{storagedata.name}}</span>
    <p class="mb-0 font-roboto">{{storagedata.username}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
   <li><a href="#" routerLink="settings/common-settings"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
 <!-- <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li> -->
  <!-- <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li><a href="javascript:void(0)" (click)="logout()" ><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Logout</span></a></li>
</ul>
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
    public isLoading = new BehaviorSubject(false);
  constructor() {
     
    console.log(this.isLoading);
    
   }

  show() {
    this.isLoading.next(true);
  }

  hide() {
      this.isLoading.next(false);
  }
  
}

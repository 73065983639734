<section class="login-bg">

  <div class="container-fluid">
    <div class="row">
      <!-- <div class="col-xl-6" [ngStyle]="{'background-image': 'url(assets/images/login/loginnew.jpeg)',
                                        'background-size': 'cover',
                                        'background-position': 'center center',
                                        'display': 'block'}">
        <img class="bg-img-cover bg-center d-none" src="assets/images/login/loginnew.jpg" alt="looginpage">
      </div> -->
  <div class="login-inner">
      <div class="col-xl-6"><div class="d-flex justify-content-center">
        <img src="assets/images/login/login-left.jpg" class="img-fluid">
      </div></div>

      <div class="login-card">
      
          <div>
            <div>
              <a class="logo"  routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/logo11.png" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo11.png" alt="looginpage">
              </a>
            </div>
            <div class="login-main"> 
              <form class="theme-form needs-validation" [class.was-validated]="!loginForm.valid"  [formGroup]="loginForm">
                <h4>Sign in to account</h4>
                <p>Enter your email & password to login</p>
                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input class="form-control" type="text" required="" placeholder="Username" formControlName="email">
                  <div class="invalid-tooltip" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required">Username is required.</div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********" formControlName="password">
                  <div class="invalid-tooltip" *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">Password is required.</div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                </div>
                <div class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Remember password</label>
                  </div>
                  <a [routerLink]="'/auth/resetpassword'" class="link">Forgot password?</a>
                  <button class="btn btn-primary btn-block" type="button" (click)="login()" [disabled]="!loginForm.valid">Sign in</button>
                </div>
                 
                <!-- <p class="mt-4 mb-0">Don't have account?
                  <a [routerLink]="'/auth/changepwd'" class="ml-2">Create Account</a>
                </p> -->
              </form>
            </div>
          </div>

      </div>
    </div>
    </div>
  </div>
  </section>
  
  
   
        <ng-template #content let-c="close" let-d="dismiss">
          <!-- <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Hi there!</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
          <div class="modal-body">
            <h3>Please enter the 6-digit verification code we sent via SMS:</h3>
            <div class="forgot-img"><img img="" src="assets/otp.png"></div>
            <div class="form-row px-3">
              <div class="form-group mx-auto mt-3">
                <h6>Enter your OTP </h6>
                <div class="otp-wrap">
                  <input #otpInput [type]="onlyNumbers" [(ngModel)]="otp" class="form-control" [attr.maxlength]="otpMaxLength"
                    [attr.minlength]="otpMinLength" (keyup)="keyUpEvent($event)" placeholder="OTP">  
            
                   
                    <div class="mt-3">
                      <span *ngIf="submitOtp">
                        <button type="submit ml-2" class="btn btn-primary-outline" (click)="onSubmitOTP()">Submit OTP</button>
                      </span>
                    </div>
            
                    <div class="ml-2 d-flex mt-3 otp-recd">
                      <span class="mr-2">didn't get the OTP?</span>
                      <a href="javascript:void(0)" (click)="onResendOtp()">Resend OTP</a>
                    </div>
               
                </div>
              </div>
            </div> 
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Save</button>
          </div>
        </ng-template>
       
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { TemplateModalOverlayRef } from '../classes/template-modal.ref';
import { CustomOverlayAnimationConfig } from '../interfaces/animation-modal-data';
import { customOverlayPosition } from '../interfaces/customPosition-modal-data';
import { CommonFunctionsService } from './common-functions.service';


export interface CustomOverlayConfig {
    hasBackdropClick?: boolean;
    hasEscapeKeyClose?: boolean;
    size?: any;
    isCentered?: boolean;

    customOverlayPosition?: customOverlayPosition | null;

    isAnimation?: CustomOverlayAnimationConfig | null;

}


interface FormModalConfig {
    panelClass?: string;
    hasBackdrop?: boolean;
    backdropClass?: string;
}

const DEFAULT_CONFIG: FormModalConfig = {
    hasBackdrop: true,
    backdropClass: 'my-overlay-modal-template-backdrop',
    panelClass: 'my-modal-template-panel',

};
const DEFAULT_CUSTOM_CONFIG: CustomOverlayConfig = {
    hasBackdropClick: false,
    hasEscapeKeyClose: false,
    isCentered: true,
    size: null,
};

@Injectable()
export class TemplateModalService {



    public dialogRef!: TemplateModalOverlayRef;
    public customConfig!: CustomOverlayConfig;
    constructor(
        private injector: Injector,
        private overlay: Overlay,
        private _CommonFunctionsService: CommonFunctionsService
    ) { }

    open(templateRef: CdkPortal, config: FormModalConfig = {}, customConfig: CustomOverlayConfig = {}) {
        // Returns an OverlayRef (which is a PortalHost)
        this.customConfig = { ...DEFAULT_CUSTOM_CONFIG, ...customConfig };
        const modalConfig = { ...DEFAULT_CONFIG, ...config };
        const overlayRef = this.createOverlay(modalConfig);
        const dialogRef = new TemplateModalOverlayRef(overlayRef);

        const overlayComponent = this.attachModalContainer(overlayRef, modalConfig, dialogRef, templateRef);
        overlayRef.backdropClick().subscribe((_) => {
            if (this.customConfig.hasBackdropClick) {
                dialogRef.close(customConfig);
            }
        });
        overlayRef.keydownEvents().subscribe((_: KeyboardEvent) => {
            if (_.key == 'Escape') {
                if (this.customConfig.hasEscapeKeyClose) {
                    dialogRef.close(customConfig);
                }
            }
        });


        this.dialogRef = dialogRef;
        // console.log(overlayRef.hostElement.focus());
        overlayRef.hostElement.focus()
        return dialogRef;
    }

    private attachModalContainer(
        overlayRef: OverlayRef,
        config: FormModalConfig,
        dialogRef: TemplateModalOverlayRef,
        templateRef: CdkPortal
    ) {
        const injector = this.createInjector(config, dialogRef);
        const containerRef = overlayRef.attach(templateRef);
        return containerRef;
    }

    private createInjector(config: FormModalConfig, dialogRef: TemplateModalOverlayRef): PortalInjector {
        const injectionTokens = new WeakMap();

        injectionTokens.set(TemplateModalOverlayRef, dialogRef);

        return new PortalInjector(this.injector, injectionTokens);
    }

    private getOverlayConfig(config: FormModalConfig): OverlayConfig {
        let positionStrategy = this.overlay.position()
            .global();


        positionStrategy = this._CommonFunctionsService.setPositionStrategy(this.customConfig, positionStrategy);
        let panelCls: any = this._CommonFunctionsService.setMdlAnimation(this.customConfig, config.panelClass);





        const overlayConfig = new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: panelCls,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }

    private createOverlay(config: FormModalConfig) {
        // Returns an OverlayConfig
        const overlayConfig = this.getOverlayConfig(config);

        // Returns an OverlayRef
        return this.overlay.create(overlayConfig);
    }

    private setMdlPosition(posVal: [string, string] | boolean): string[] {
        let firstValue: string = '0px';
        let SecondValue: string = '0px';
        if (posVal.constructor === Array) {
            firstValue = posVal[0];
            SecondValue = posVal[1];
        }
        return [firstValue, SecondValue]
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";

import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { Error404Component } from './pages/error/error404/error404.component';

const routes: Routes = [
   
  {
    path: 'dashboard/default',
    redirectTo: 'dashboard/default',
    canActivate: [SecureInnerPagesGuard],
  },
  {
    path: 'auth/login',
    component: LoginComponent, 
  },
  {
    path: 'auth/resetpassword',
    component: ForgetPasswordComponent
  },

  {
    path: 'auth/changepwd',
    component: ResetPasswordComponent
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [SecureInnerPagesGuard],
    children: content
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [SecureInnerPagesGuard],
    children: full
  },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
